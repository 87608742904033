// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Set } from '@redwoodjs/router'
import NewPartnerLayout from 'src/layouts/NewPartnerLayout'
import EstimationLayout from 'src/layouts/EstimationLayout'

const Routes = () => {
  return (
    <Router>
      <Set>
        <Route path="/" page={OtherHomePage} name="home" />
      </Set>
      <Route path="/item/{id}" page={OtherItemPage} name="item" />
      <Route path="/login" page={OtherLoginPage} name="login" />
      <Route notfound page={OtherNotFoundPage} />
      <Set wrap={EstimationLayout}>
        <Route path="/{lang}/form/{partner}" page={FrontDeskFormPage} name="form" />
        <Route path="/{lang}/form/{partner}/{itemType}" page={FrontDeskForm2Page} name="form2" />
        <Route path="/{lang}/form/{partner}/{itemType}/{itemSubType}" page={FrontDeskForm3Page} name="form3" />
        <Route path="/appointment/{id}" page={FrontDeskAppointmentPage} name="appointment" />
        <Route path="/confirmed" page={FrontDeskConfirmedPage} name="confirmed" />
        <Route path="/thanks" page={FrontDeskThanksPage} name="thanks" />
      </Set>
      <Set private unauthenticated="login" wrap={NewPartnerLayout}>
        <Route path="/partner/dashboard" page={BackOfficeDashboardPage} name="dashboard" />
        <Route path="/partner/accountSettings" page={BackOfficeAccountSettingsPage} name="accountSettings" />
        <Route path="/partner/diagnostic" page={BackOfficeDiagnosticPage} name="diagnostic" />
        <Route path="/partner/work" page={BackOfficeWorkPage} name="work" />
        <Route path="/partner/marketing" page={BackOfficeMarketingPage} name="marketing" />
        <Route path="/partner/reports" page={BackOfficeReportsPage} name="reports" />
        <Route path="/partner/diagnostic/{id}" page={BackOfficeDiagnosticItemPage} name="diagnosticItem" />
        <Route path="/partner/work/{id}" page={BackOfficeWorkItemPage} name="workItem" />
        <Route path="/partner/marketing/{id}" page={BackOfficeMarketingItemPage} name="marketingItem" />
        <Route path="/partner/done/{id}" page={BackOfficeDoneItemPage} name="doneItem" />
        <Route path="/partner/itemImages/{id}" page={BackOfficeItemImagesPage} name="itemImages" />
      </Set>
      <Set private unauthenticated="login">
        <Route path="/partner/enter/{id}" page={BackOfficeEnterItemPage} name="enterItem" />
      </Set>
    </Router>
  )
}

export default Routes
