import { useTranslation } from 'react-i18next'
import logo from 'web/src/layouts/EstimationLayout/logo_Raccoon_typo_H_black.png'

const EstimationLayout = ({ children }) => {
  const { t } = useTranslation()
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        {children}
        <div className="text-center mt-12">
          {t('EstimationLayout.poweredBy')}
          <div className="flex items-center justify-center">
            <img src={logo} alt="" width="170px" />
          </div>
          {/* {t('EstimationLayout.copyrightShort')} */}
        </div>
      </div>
    </div>
  )
}

export default EstimationLayout
