import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Avatar from 'boring-avatars'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {
  DocumentReportIcon,
  ClipboardCheckIcon,
  CogIcon,
  PresentationChartLineIcon,
  HomeIcon,
  XIcon,
  MenuIcon,
  LogoutIcon,
  AdjustmentsIcon,
  TranslateIcon,
  SunIcon,
  MoonIcon,
} from '@heroicons/react/outline'
import { Link, NavLink, routes } from '@redwoodjs/router'
import { useAuth } from '@redwoodjs/auth'
import logo from './logo_Raccoon_typo_H_blanc.png'

function useStickyState(defaultValue, key) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(key)
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue
  })
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])
  return [value, setValue]
}

const NewPartnerLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [appearanceMode, setAppearanceMode] = useStickyState(
    'light',
    'appearance'
  )

  const { logIn, logOut, isAuthenticated, currentUser } = useAuth()

  const { t, i18n } = useTranslation()
  const navigation = [
    {
      name: t('PartnerLayout.dashboard'),
      href: routes.dashboard(),
      icon: HomeIcon,
    },
    {
      name: t('PartnerLayout.diagnostic'),
      href: routes.diagnostic(),
      icon: ClipboardCheckIcon,
    },
    {
      name: t('PartnerLayout.work'),
      href: routes.work(),
      icon: CogIcon,
    },
    {
      name: t('PartnerLayout.marketing'),
      href: routes.marketing(),
      icon: PresentationChartLineIcon,
    },
    {
      name: t('PartnerLayout.report'),
      href: routes.reports(),
      icon: DocumentReportIcon,
    },
  ]

  function toFrench() {
    i18n.changeLanguage('fr')
    moment.locale('fr')
  }
  function toEnglish() {
    i18n.changeLanguage('en')
    moment.locale('en')
  }

  function handleAppearanceChange() {
    if (appearanceMode == 'dark') {
      setAppearanceMode('light')
      // Whenever the user explicitly chooses light mode
      localStorage.appearance = 'light'
    } else {
      setAppearanceMode('dark')
      // Whenever the user explicitly chooses dark mode
      localStorage.appearance = 'dark'
    }
  }

  return (
    <>
      <div className={appearanceMode == 'dark' ? 'dark' : ''}>
        <div className="h-screen flex overflow-hidden bg-gray-100 dark:bg-gray-50">
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              static
              className="fixed inset-0 flex z-40 md:hidden"
              open={sidebarOpen}
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                    <div className="flex-shrink-0 flex items-center px-4">
                      <img className="h-8 w-auto" src={logo} alt="Workflow" />
                    </div>
                    <nav className="mt-5 px-2 space-y-1">
                      {navigation.map((item) => (
                        <NavLink
                          to={item.href}
                          key={item.name}
                          className="group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-300 hover:bg-gray-700 hover:text-white"
                          activeClassName="group flex items-center px-2 py-2 text-sm font-medium rounded-md bg-gray-900 text-white"
                        >
                          <item.icon
                            className="text-gray-300 mr-4 flex-shrink-0 h-6 w-6"
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      ))}
                    </nav>
                  </div>
                  <Transition
                    show={isShowing}
                    enter="transition ease duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="flex flex-row items-center bg-gray-800 p-2 h-auto border-t-2 border-white">
                      <ul className="text-sm font-medium text-gray-300">
                        <li
                          className="cursor-pointer group flex flex-1 items-center px-2 py-2 text-sm font-medium rounded-md text-gray-300 hover:bg-gray-700 hover:text-white"
                          onClick={() => {
                            t('PartnerLayout.lang')
                              .charAt(0)
                              .toLocaleLowerCase() == 'f'
                              ? toFrench()
                              : toEnglish()
                          }}
                          aria-hidden="true"
                        >
                          <TranslateIcon className="text-gray-300 mr-4 flex-shrink-0 h-6 w-6" />
                          {t('PartnerLayout.lang')}
                        </li>
                        <li
                          className="cursor-pointer group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-300 hover:bg-gray-700 hover:text-white"
                          aria-hidden="true"
                        >
                          <AdjustmentsIcon className="text-gray-300 mr-4 flex-shrink-0 h-6 w-6" />
                          <Link to={routes.accountSettings()}>
                            {t('PartnerLayout.accountSettings')}
                          </Link>
                        </li>
                        <li
                          className="cursor-pointer group flex flex-1 items-center px-2 py-2 text-sm font-medium rounded-md text-gray-300 hover:bg-gray-700 hover:text-white"
                          onClick={isAuthenticated ? logOut : logIn}
                          aria-hidden="true"
                        >
                          <LogoutIcon className="text-gray-300 mr-4 flex-shrink-0 h-6 w-6" />
                          {t('PartnerLayout.logOut')}
                        </li>
                      </ul>
                    </div>
                  </Transition>
                  <div className="flex-shrink-0 flex bg-gray-700 dark:bg-gray-900 p-4">
                    <div className="flex-shrink-0 group block">
                      <div className="flex items-center">
                        <div>
                          {currentUser.avatar && currentUser.avatar != '' ? (
                            <div className="inline-block h-9 w-9">
                              <img
                                className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                src={currentUser.avatar}
                                alt="userAvatar"
                              ></img>
                            </div>
                          ) : (
                            <>
                              <Avatar
                                size={40}
                                name="defaultAvatar"
                                variant="beam"
                                colors={[
                                  '#92A1C6',
                                  '#146A7C',
                                  '#F0AB3D',
                                  '#C271B4',
                                  '#C20D90',
                                ]}
                              />
                            </>
                          )}
                        </div>
                        <div className="ml-3">
                          <p className="text-xs font-medium text-white">
                            {currentUser.fullName
                              ? currentUser.fullName
                              : currentUser.email}
                          </p>
                          <p
                            className="text-xs font-medium text-gray-300 group-hover:text-gray-200 cursor-pointer"
                            onClick={() =>
                              setIsShowing((isShowing) => !isShowing)
                            }
                            aria-hidden="true"
                          >
                            {isShowing
                              ? t('PartnerLayout.hideSettings')
                              : t('PartnerLayout.viewSettings')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 w-14">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </Dialog>
          </Transition.Root>

          {/* DESKTOP / FULL SIDEBAR */}
          <div className="hidden md:flex md:flex-shrink-0">
            <div className="flex flex-col w-64">
              <div className="flex flex-col h-0 flex-1 bg-gray-800">
                <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                  <div className="flex items-center flex-shrink-0 px-4">
                    <img className="h-8 w-auto" src={logo} alt="Workflow" />
                  </div>
                  <nav className="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
                    {navigation.map((item) => (
                      <NavLink
                        to={item.href}
                        key={item.name}
                        className="group flex items-center px-2 py-2 font-medium rounded-md text-gray-300 hover:bg-gray-700 hover:text-white"
                        activeClassName="group flex items-center px-2 py-2 font-medium rounded-md bg-gray-900 text-white"
                      >
                        <item.icon
                          className="text-gray-300 mr-4 flex-shrink-0 h-6 w-6"
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    ))}
                  </nav>
                </div>
                <Transition
                  show={isShowing}
                  enter="transition ease duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="flex flex-row items-center bg-gray-800 p-2 h-auto border-t-2 border-white">
                    <ul className="text-sm font-medium text-gray-300 w-full">
                      <li
                        className="cursor-pointer group flex flex-1 items-center px-2 py-2 text-sm font-medium rounded-md text-gray-300 hover:bg-gray-700 hover:text-white"
                        onClick={() => {
                          t('PartnerLayout.lang')
                            .charAt(0)
                            .toLocaleLowerCase() == 'f'
                            ? toFrench()
                            : toEnglish()
                        }}
                        aria-hidden="true"
                      >
                        <TranslateIcon className="text-gray-300 mr-4 flex-shrink-0 h-6 w-6" />
                        {t('PartnerLayout.lang')}
                      </li>
                      <li
                        className="cursor-pointer group flex flex-1 items-center px-2 py-2 text-sm font-medium rounded-md text-gray-300 hover:bg-gray-700 hover:text-white"
                        onClick={() => handleAppearanceChange()}
                        aria-hidden="true"
                      >
                        {appearanceMode == 'dark' ? (
                          <SunIcon className="text-gray-300 mr-4 flex-shrink-0 h-6 w-6" />
                        ) : (
                          <MoonIcon className="text-gray-300 mr-4 flex-shrink-0 h-6 w-6" />
                        )}
                        {t('PartnerLayout.appearance')}
                      </li>
                      <li
                        className="cursor-pointer group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-300 hover:bg-gray-700 hover:text-white"
                        aria-hidden="true"
                      >
                        <AdjustmentsIcon className="text-gray-300 mr-4 flex-shrink-0 h-6 w-6" />
                        <Link to={routes.accountSettings()}>
                          {t('PartnerLayout.accountSettings')}
                        </Link>
                      </li>
                      <li
                        className="cursor-pointer group flex flex-1 items-center px-2 py-2 text-sm font-medium rounded-md text-gray-300 hover:bg-gray-700 hover:text-white"
                        onClick={isAuthenticated ? logOut : logIn}
                        aria-hidden="true"
                      >
                        <LogoutIcon className="text-gray-300 mr-4 flex-shrink-0 h-6 w-6" />
                        {t('PartnerLayout.logOut')}
                      </li>
                    </ul>
                  </div>
                </Transition>
                <div className="flex-shrink-0 flex flex-row bg-gray-700 dark:bg-gray-900 p-4">
                  <div className="flex-shrink-0 w-full group block">
                    <div className="flex items-center">
                      <div>
                        {currentUser.avatar && currentUser.avatar != '' ? (
                          <div className="inline-block h-9 w-9">
                            <img
                              className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                              src={currentUser.avatar}
                              alt="userAvatar"
                            ></img>
                          </div>
                        ) : (
                          <>
                            <Avatar
                              size={40}
                              name="defaultAvatar"
                              variant="beam"
                              colors={[
                                '#92A1C6',
                                '#146A7C',
                                '#F0AB3D',
                                '#C271B4',
                                '#C20D90',
                              ]}
                            />
                          </>
                        )}
                      </div>
                      <div className="ml-3">
                        <p className="text-xs font-medium text-white">
                          {currentUser.fullName
                            ? currentUser.fullName
                            : currentUser.email}
                        </p>
                        <p
                          className="text-xs font-medium text-gray-300 group-hover:text-gray-200 cursor-pointer"
                          onClick={() =>
                            setIsShowing((isShowing) => !isShowing)
                          }
                          aria-hidden="true"
                        >
                          {isShowing
                            ? t('PartnerLayout.hideSettings')
                            : t('PartnerLayout.viewSettings')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
              <button
                className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <main
              className={
                appearanceMode == 'dark'
                  ? 'flex-1 relative z-0 overflow-y-auto focus:outline-none bg-gradient-to-b from-gray-700 via-gray-800 to-gray-900'
                  : 'flex-1 relative z-0 overflow-y-auto focus:outline-none bg-gray-100'
              }
            >
              <div className="py-6">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <h1 className="text-2xl font-semibold text-gray-900">
                    {/* To Do: {item.name} */}
                  </h1>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                  {children}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}
export default NewPartnerLayout
